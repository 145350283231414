import '../bootstrap';

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { Flex, Footer, GlobalStyle, Nav, theme } from '@glean/component-library/build/serverSide';

import { SEO } from '../components/seo';
import { getSiteHost } from '../urlHelper';

const Iframe = styled.iframe`
    border: none;
    width: 100%;
    height: 100%;
    display: block;
    max-width: 800px;
    margin: auto;
    margin-bottom: -60px;
`;

const App = () => {
    const onLogoClick = () => {};
    return (
        <ThemeProvider theme={theme}>
            <SEO />
            <GlobalStyle />
            <Flex height="100vh" width="100%" alignItems="center" flexDirection="column">
                <Nav onLogoClick={onLogoClick} />

                <Iframe src="https://docs.google.com/document/d/e/2PACX-1vSXMyFzLR-nH1rC3WgMSkmUVPoPoik1aSK9EnoSixPRA2n35ududg3asvyL3avSgtVvYs2VTlBqFFey/pub?embedded=true" />

                <Footer siteUrl={getSiteHost()} onLogoClick={onLogoClick} />
            </Flex>
        </ThemeProvider>
    );
};

export default App;
